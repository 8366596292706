// src/redux/store.ts

import { combineReducers } from '@reduxjs/toolkit';
import { configureStore as configureStoreRTK } from '@reduxjs/toolkit';
import { cartSlice } from './slices/cartSlice';
import { wishlistSlice } from './slices/wishlistSlice';
import { compareSlice } from './slices/compareSlice';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  Persistor,
} from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

// Fallback for SSR environments where 'window' is not available
const createNoopStorage = () => {
  return {
    getItem(_key: any) {
      return Promise.resolve(null);
    },
    setItem(_key: any, value: any) {
      return Promise.resolve(value);
    },
    removeItem(_key: any) {
      return Promise.resolve();
    },
  };
};

// Use localStorage or noop storage depending on the environment
const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

// Configuration for redux-persist
const persistConfig = {
  key: 'root', // The key for the persisted state
  version: 1,  // Version of the persisted state
  storage,     // Storage engine (localStorage or noop)
};

// Combine reducers from different slices and wrap with persistReducer
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    cart: cartSlice.reducer,
    wishlist: wishlistSlice.reducer,
    compare: compareSlice.reducer,
  })
);

// Configure Redux store with the persisted reducer and middleware
const store = configureStoreRTK({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ], // Ignored actions for serializability check
      },
    }),
});

// Types for Redux state, dispatch, and persistor
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export type AppPersistor = Persistor;

// Create and export the persistor
export const persistor = persistStore(store);

export default store;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductsType } from "@/interfaces/interFace";
import { toast } from "sonner";

// Define the interface for the compare state
interface CompareState {
  compareProducts: ProductsType[];
}

// Initial state for the compare slice
const initialState: CompareState = {
  compareProducts: [],
};

// Define the compare slice
export const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    cart_compare_product: (state, { payload }: PayloadAction<ProductsType>) => {
      const productIndex = state.compareProducts.findIndex(
        (item) => item.id === payload.id
      );

      if (productIndex >= 0) {
        toast.info("Product already in compare list", {
          duration: 1000,
        });
      } else {
        state.compareProducts.push(payload);
        toast.success(`${payload.name} added to compare list`, {
          duration: 1000,
        });
      }
    },
    remove_compare_product: (
      state,
      { payload }: PayloadAction<ProductsType>
    ) => {
      state.compareProducts = state.compareProducts.filter(
        (item) => item.id !== payload.id
      );
      toast.error(`${payload.name} removed from compare list`, {
        duration: 1000,
      });
    },
    clear_compare: (state) => {
      state.compareProducts = [];
      toast.success("Compare list cleared", {
        duration: 1000,
      });
    },
  },
});

// Export the actions and reducer
export const {
  cart_compare_product,
  remove_compare_product,
  clear_compare,
} = compareSlice.actions;

export default compareSlice.reducer;

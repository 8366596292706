"use client";

import { useEffect } from "react";
import "../style/index.scss";
import "./globals.css";
import AppProvider from "@/contextApi/AppProvider";
import ReduxProvider from "@/redux/provider";
import { Toaster } from "sonner";
import SidebarCard from "@/components/SharedComponents/Sidebars/SidebarCard";
import Wichlist from "@/components/SharedComponents/Sidebars/SidebarWishlist";
import CompareMain from "@/components/SharedComponents/Sidebars/SidebarWishlist";

// Function to send purchase event to Facebook Pixel
const sendPurchaseEvent = (eventData) => {
  if (typeof window !== "undefined" && window.fbq) {
    window.fbq('track', 'Purchase', {
      value: eventData.custom_data.value,
      currency: eventData.custom_data.currency,
      content_name: 'Product Purchase', // Customize the product name if needed
      content_ids: eventData.original_event_data.event_name, // Example: using event_name as content_id
      content_type: 'product',
    });
  }
};

export default function RootLayout({ children }) {
  const eventData = {
    event_name: "Purchase",
    event_time: 1731291334,
    action_source: "website",
    user_data: {
      em: [
        "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068" // Example email hash
      ],
      ph: [null],
    },
    custom_data: {
      currency: "USD",
      value: "142.52", // Example purchase value
    },
    original_event_data: {
      event_name: "Purchase",
      event_time: 1731291334
    },
  };

  // Send the purchase event when component mounts
  useEffect(() => {
    sendPurchaseEvent(eventData);
  }, [eventData]);

  return (
    <>
      <html lang="en">
        <head>
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta name="description" content="Ko Gear" />
          <meta name="robots" content="noindex, follow" />
          <meta name="google-site-verification" content="0PZrzP4khKNFWzTJu6yKmASSlq1rvDtrW8zTDw8iS50" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <link rel="icon" href="./favicon.co" />
          <link
            href="https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap"
            rel="stylesheet"
          />

          {/* Meta Pixel Code */}
          <script
            dangerouslySetInnerHTML={{
              __html: `
                !function(f,b,e,v,n,t,s) {
                  if(f.fbq)return; n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)}; 
                  if(!f._fbq)f._fbq=n; n.push=n; n.loaded=!0; n.version='2.0'; 
                  n.queue=[]; t=b.createElement(e); t.async=!0; 
                  t.src=v; s=b.getElementsByTagName(e)[0]; 
                  s.parentNode.insertBefore(t,s)
                }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js'); 
                fbq('init', '1279246889813672'); 
                fbq('track', 'PageView');
              `,
            }}
          />
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: "none" }}
              src="https://www.facebook.com/tr?id=1279246889813672&ev=PageView&noscript=1"
            />
          </noscript>
          {/* End Meta Pixel Code */}
        </head>

        <body suppressHydrationWarning={true}>
          <ReduxProvider>
            <AppProvider>
              <Wichlist />
              <CompareMain />
              <SidebarCard />
              {children}
            </AppProvider>
            <Toaster position="top-center" richColors />
          </ReduxProvider>
        </body>
      </html>
    </>
  );
}
